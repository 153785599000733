import React, { useState, useEffect } from 'react';
import Sign from './services/websigner.jpg';

// Modal Component
const Modal = ({ isOpen, closeModal, content }) => {
  useEffect(() => {
    if (isOpen) {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling
      document.body.style.overflow = 'auto';
    }

    // Cleanup to ensure scrolling is restored if the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-4xl h-auto">
        <button
          onClick={closeModal}
          className="bg-red-500 text-white px-4 py-2 mr-5 mt-10 rounded float-right">
          Close
        </button>
        <div className="mt-4">{content}</div>
      </div>
    </div>
  );
};

const Digitalsignature = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-sky-50 flex flex-col items-center">
      <div className="max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
        <div className="lg:flex lg:justify-between lg:items-center">
          <div className="lg:w-2/3">
            <h1 className="text-7xl font-bold text-gray-900">
            Your documents, your way. Securely signed, anywhere.
            </h1>
            <p className="mt-6 text-3xl text-gray-700">
            Web signer solutions streamline business processes by eliminating manual paperwork, reducing costs, and ensuring efficient document management. These solutions are crucial for organizations seeking to enhance efficiency and compliance.
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:w-1/3">
            <div className="relative">
              <img className="rounded-lg shadow-lg ml-10" src={Sign} alt="Digital Signature" />
            </div>
          </div>
        </div>

        {/* Statistics */}
        <div className="m-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          
        </div>

        {/* Benefits Section */}
        <div className="flex justify-center gap-10">
        
        
        <div className="p-6 bg-blue-100 rounded-lg text-center w-1/2">
        <div className="text-blue-500 mb-4">
        <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M5.005 11.19V12l6.998 4.042L19 12v-.81M5 16.15v.81L11.997 21l6.998-4.042v-.81M12.003 3 5.005 7.042l6.998 4.042L19 7.042 12.003 3Z" />
</svg>
            </div>
          <h4 className="text-xl font-semibold mb-2">Dive further</h4>
          <p className="text-gray-600">
          streamline business processes, reducing costs and ensuring efficient document management
          </p>
        
          <button
            onClick={() =>
              openModal(
                <div>
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M5.005 11.19V12l6.998 4.042L19 12v-.81M5 16.15v.81L11.997 21l6.998-4.042v-.81M12.003 3 5.005 7.042l6.998 4.042L19 7.042 12.003 3Z" />
</svg>
                  <h3 className="text-2xl font-semibold text-gray-900 text-center">More details</h3>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  Digital signing solutions have revolutionized how businesses handle contracts and agreements. By
eliminating the need for physical documents, businesses can save time, reduce costs, and minimize
errors associated with manual paperwork.</p>

<p className="mt-4 text-xl text-gray-600 leading-relaxed">
  Many organizations, facilitating seamless management of
core business processes such as finance, HR, procurement, and supply chain management,
integrating digital signing solutions in their workflow and systems brings about a synergy that
enhances overall organizational efficiency.
                  </p>
                  
                </div>
              )
            }
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
            Read more
          </button>
        </div>

        {/* Key Features Section */}
        <div className="p-6 bg-green-100 rounded-lg text-center w-1/2 ">
        <div className="text-green-500 mb-4 ">
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                  </svg>
                </div>
          <h4 className="text-xl font-semibold mb-2">Key Features</h4>
          <p className="text-gray-600">
          Web signer solutions streamline workflows, enhance data security, and reduce costs, while ensuring compliance and improving collaboration.
          </p>
        
          <button
            onClick={() =>
              openModal(
                <div className=" p-6 rounded-lg ">
                <div className='mt-10'>
                <div className="text-green-500 mb-4 ">
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                  </svg>
                </div>
                <h3 className="text-2xl font-semibold text-gray-900 text-center">Key Features</h3>
                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Streamlined Workflows:</span> Integrating digital signing into workflows automates the document
lifecycle from creation to approval and signing. This reduces manual processing time and speeds
up decision-making.
                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Data Accuracy and Compliance:</span> Encryption maintains data integrity and ensures regulatory
compliance. Signed documents are securely stored and easily accessible, enhancing auditability
and governance.

                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Multiple Signature Options:</span> Signers can choose from legally valid signing methods. For those
without a Digital Signature Certificate, Aadhaar e-sign is available, ensuring compliance and
accessibility.</p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>E-Stamp Repository:</span> Integrated e-stamps streamline legal procedures and contract
                  management by providing on-demand access within the ecosystem.
                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Enhanced Collaboration:</span> Managing documents within workflows improves collaboration by
offering real-time access to signed agreements, promoting transparency, and improving
communication across teams and locations.
                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Cost Savings:</span> Digital signing eliminates costs related to printing, mailing, and storing physical
                  documents. It also accelerates contract execution and revenue realization.
                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Security and Risk Mitigation:</span> Web-based signing solutions provide robust security measures,
                  including encryption and authentication, to protect sensitive data and ensure secure storage.
                </p>
                </div>
                </div>
              )
            }
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
            Read more
          </button>
        </div>
      </div>

      {/* Modal Component */}
      <Modal isOpen={isModalOpen} closeModal={closeModal} content={modalContent} />
    </div>
    </div>
  );
};

export default Digitalsignature;

