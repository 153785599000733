import React, { useState } from 'react';
import './Navbar.css';
import Dropdown from './dropdown';
import Logo from './blogpics/logo.jpg';
import Dropdown2 from './dropdown2';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdown2Open, setIsDropdown2Open] = useState(false);

  const navigate = useNavigate(); // For programmatic navigation

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (open) => {
    setIsDropdownOpen(open);
  };

  const toggleDropdown2 = (open) => {
    setIsDropdown2Open(open);
  };

  // Handle navigation and smooth scroll for "About Us"
  const handleAboutUsClick = (e) => {
    e.preventDefault();
    navigate('/');
    setTimeout(() => {
      const aboutSection = document.getElementById('aboutus');
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  // Handle navigation and smooth scroll for "Clients"
  const handleClientsClick = (e) => {
    e.preventDefault();
    navigate('/');
    setTimeout(() => {
      const clientsSection = document.getElementById('customers');
      if (clientsSection) {
        clientsSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            <img src={Logo} alt="Plasmic" className="navbar-logo-img" />
          </Link>
          <div className="menu-icon" onClick={toggleMenu}>
            <i className={isOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
          </div>
          <ul className={isOpen ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <Link to="/" className="nav-links">Home</Link>
            </li>
            <li
              className={`nav-item ${isDropdownOpen ? 'show' : ''}`}
              onMouseEnter={() => toggleDropdown(true)}
              onMouseLeave={() => toggleDropdown(false)}
            >
              <Link to="#" className="nav-links">
                Solutions <i className="fas fa-caret-down"></i>
              </Link>
              <Dropdown />
            </li>
            <li className="nav-item">
              {/* Clients link with smooth scroll */}
              <a href="/" onClick={handleClientsClick} className="nav-links">
                Clients
              </a>
            </li>
            <li
              className={`nav-item ${isDropdown2Open ? 'show' : ''}`}
              onMouseEnter={() => toggleDropdown2(true)}
              onMouseLeave={() => toggleDropdown2(false)}
            >
              <Link to="#" className="nav-links">
                Resources <i className="fas fa-caret-down"></i>
              </Link>
              <Dropdown2 />
            </li>
            <li className="nav-item">
              {/* About Us link with smooth scroll */}
              <a href="/" onClick={handleAboutUsClick} className="nav-links">
                About us
              </a>
            </li>
            <li className="nav-item">
              <a href="#contactus" className="nav-links">Contact us</a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
