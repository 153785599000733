import React from 'react';
import Fdss from './blogpics/fdss.png';
import './about.css';

const StatsSection = () => {
  return (
    <section className="bg-gradient-to-br from-blue-50 to-purple-100 py-16" id='aboutus'>
      <div className="container mx-auto px-6 md:px-12 lg:px-24">
        {/* Title */}
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-purple-900">
            ISO and DigiReady certified, your trusted partner for FinTech solutions
          </h2>
        </div>

        {/* Stats Row */}
        <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
          {/* Card 1 */}
          <div className="bg-white shadow-lg rounded-lg p-4 md:p-6 text-center">
            <h3 className="text-3xl md:text-4xl font-extrabold text-purple-800">19+</h3>
            <p className="text-sm md:text-base text-gray-600 mt-2">Yrs of Service</p>
          </div>

          {/* Card 2 */}
          <div className="bg-white shadow-lg rounded-lg p-4 md:p-6 text-center">
            <h3 className="text-3xl md:text-4xl font-extrabold text-purple-800">1mn+</h3>
            <p className="text-sm md:text-base text-gray-600 mt-2">Certificates issued</p>
          </div>

          {/* Card 3 */}
          <div className="bg-white shadow-lg rounded-lg p-4 md:p-6 text-center">
            <h3 className="text-3xl md:text-4xl font-extrabold text-purple-800">200+</h3>
            <p className="text-sm md:text-base text-gray-600 mt-2">Projects Delivered</p>
          </div>

          {/* Card 4 */}
          <div className="bg-white shadow-lg rounded-lg p-4 md:p-6 text-center">
            <h3 className="text-3xl md:text-4xl font-extrabold text-purple-800">50k+</h3>
            <p className="text-sm md:text-base text-gray-600 mt-2">Active Users</p>
          </div>

          {/* Card 5 */}
          <div className="bg-white shadow-lg rounded-lg p-4 md:p-6 text-center">
            <h3 className="text-3xl md:text-4xl font-extrabold text-purple-800">20+</h3>
            <p className="text-sm md:text-base text-gray-600 mt-2">Locations</p>
          </div>
        </div>

        {/* Map and Details */}
        <div className="mt-16 flex flex-col lg:flex-row items-center">
          {/* Map Image */}
          <div className="lg:w-1/4 w-full mb-8 lg:mb-0">
            <img
              src={Fdss} // Replace this with your map image URL
              alt='Map'
              className="mx-auto rounded-lg"
            />
          </div>

          {/* Description */}
          <div className="w-full lg:pl-12 text-justify">
            <p className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-2">
              Fourth Dimension Service Solutions is a leading FinTech innovator, bridging the gap between traditional and digital finance. We offer cutting-edge solutions prioritizing speed, security, and customization.
            </p>
            <p className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-2">
              Our advanced digital signature services revolutionize document handling, streamlining workflows and driving efficiency in today's digital-first world. We empower businesses to transition from outdated manual processes to secure, legally binding digital and electronic signatures.
            </p>
            <p className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-2">
              Our platform optimizes operations, making them more efficient, cost-effective, and environmentally friendly. By integrating our services, organizations not only enhance efficiency but also contribute to a more sustainable future.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatsSection;