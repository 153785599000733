import React, { useState, useEffect } from 'react';
import Sign from './services/AML.jpg';

// Modal Component
const Modal = ({ isOpen, closeModal, content }) => {
  useEffect(() => {
    if (isOpen) {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling
      document.body.style.overflow = 'auto';
    }

    // Cleanup to ensure scrolling is restored if the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-4xl h-auto">
        <button
          onClick={closeModal}
          className="bg-red-500 text-white px-4 py-2 mr-5 mt-10 rounded float-right">
          Close
        </button>
        <div className="mt-4">{content}</div>
      </div>
    </div>
  );
};

const Digitalsignature = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-sky-50 flex flex-col items-center">
      <div className="max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
        <div className="lg:flex lg:justify-between lg:items-center">
          <div className="lg:w-2/3">
            <h1 className="text-7xl font-bold text-gray-900">
            Seamless AML integration, robust compliance
            </h1>
            <p className="mt-6 text-3xl text-gray-700">
            Our AML Screening Services Integration is designed to effortlessly incorporate advanced
anti-money laundering screening capabilities into your existing systems. By integrating our
services, you can strengthen your compliance framework and enhance your risk management
processes without disrupting your operations.
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:w-1/3">
            <div className="relative">
              <img className="rounded-lg shadow-lg ml-10" src={Sign} alt="Digital Signature" />
            </div>
          </div>
        </div>

        {/* Statistics */}
        <div className="m-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          
        </div>

        {/* Benefits Section */}
        <div className="flex justify-center gap-10">
        
        
        <div className="p-6 bg-blue-100 rounded-lg text-center w-1/2">
        <div className="text-blue-500 mb-4">
        <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M5.005 11.19V12l6.998 4.042L19 12v-.81M5 16.15v.81L11.997 21l6.998-4.042v-.81M12.003 3 5.005 7.042l6.998 4.042L19 7.042 12.003 3Z" />
</svg>
            </div>
          <h4 className="text-xl font-semibold mb-2">Key features</h4>
          <p className="text-gray-600">
          seamless API integration, customizable workflows, comprehensive data coverage, and scalability, ensuring efficient compliance across various business sizes and operations.
          </p>
        
          <button
            onClick={() =>
              openModal(
                <div>
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M5.005 11.19V12l6.998 4.042L19 12v-.81M5 16.15v.81L11.997 21l6.998-4.042v-.81M12.003 3 5.005 7.042l6.998 4.042L19 7.042 12.003 3Z" />
</svg>
                  <h3 className="text-2xl font-semibold text-gray-900 text-center">Key Features</h3>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>API Integration:</span> Easily connect our AML screening capabilities to your existing
platforms using our robust API, allowing for real-time data exchanges and automated
processes.
                </p>

                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Customizable Workflows:</span> Tailor the integration to fit your specific business
processes, ensuring that screening fits seamlessly into your customer onboarding and
transaction monitoring workflows.
                </p>
                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Comprehensive Data Coverage:</span> Access a wide range of global sanctions lists, PEP
                  databases, and adverse media sources, ensuring thorough and accurate screening.
                </p>
                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Scalable Solutions:</span> Whether you’re a small business or a large financial institution,
our integration can scale to meet your needs, handling increasing volumes of data
without compromising performance.
                </p>
                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>User-Friendly Interface:</span> Maintain a consistent user experience across platforms
                  with an intuitive interface that simplifies compliance tasks and enhances productivity.
                </p>
                  
                </div>
              )
            }
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
            Read more
          </button>
        </div>

        {/* Key Features Section */}
        <div className="p-6 bg-green-100 rounded-lg text-center w-1/2 ">
        <div className="text-green-500 mb-4 ">
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                  </svg>
                </div>
          <h4 className="text-xl font-semibold mb-2">Benefits</h4>
          <p className="text-gray-600">
          efficiency through automation, enhances accuracy with advanced algorithms, provides real-time alerts for risk management, and ensures ongoing regulatory compliance.
          </p>
        
          <button
            onClick={() =>
              openModal(
                <div className=" p-6 rounded-lg ">
                <div className='mt-10'>
                <div className="text-green-500 mb-4 ">
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                  </svg>
                </div>
                <h3 className="text-2xl font-semibold text-gray-900 text-center">Benefits</h3>
                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Improved Efficiency:</span> Automate your AML screening processes, reducing manual
                  workload and allowing your team to focus on higher-value tasks.
                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Enhanced Accuracy:</span> Minimize false positives and negatives with sophisticated
algorithms and advanced data matching techniques, ensuring reliable compliance
outcomes.
                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Real-Time Alerts:</span> Stay informed with immediate notifications for any potential
                  risks, enabling timely decision-making and response.</p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Regulatory Compliance:</span> Keep pace with ever-evolving AML regulations and
requirements, ensuring your organization remains compliant with local and
international laws.
                </p>
                
                </div>
                </div>
              )
            }
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
            Read more
          </button>
        </div>
      </div>

      {/* Modal Component */}
      <Modal isOpen={isModalOpen} closeModal={closeModal} content={modalContent} />
    </div>
    </div>
  );
};

export default Digitalsignature;

