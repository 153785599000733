import React, { useState, useEffect } from 'react';
import Sign from './services/nonintegratedsol.jpg';

// Modal Component
const Modal = ({ isOpen, closeModal, content }) => {
  useEffect(() => {
    if (isOpen) {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling
      document.body.style.overflow = 'auto';
    }

    // Cleanup to ensure scrolling is restored if the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-4xl h-auto">
        <button
          onClick={closeModal}
          className="bg-red-500 text-white px-4 py-2 mr-5 mt-10 rounded float-right">
          Close
        </button>
        <div className="mt-4">{content}</div>
      </div>
    </div>
  );
};

const Digitalsignature = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-sky-50 flex flex-col items-center">
      <div className="max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
        <div className="lg:flex lg:justify-between lg:items-center">
          <div className="lg:w-2/3">
            <h1 className="text-7xl font-bold text-gray-900">
            Non-integrated, yet effective.
            </h1>
            <p className="mt-6 text-3xl text-gray-700">
            Essential for verifying electronic documents, ensuring authenticity and compliance with government regulations. Our solutions offer a cost-effective and scalable way to implement digital signatures for businesses of all sizes.
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:w-1/3">
            <div className="relative">
              <img className="rounded-lg shadow-lg ml-10" src={Sign} alt="Digital Signature" />
            </div>
          </div>
        </div>

        {/* Statistics */}
        <div className="m-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          
        </div>

        {/* Benefits Section */}
        <div className="flex justify-center gap-10">
        
        
        <div className="p-6 bg-blue-100 rounded-lg text-center w-1/2">
        <div className="text-blue-500 mb-4">
        <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="m17 13 3.4641-2V7L17 5l-3.4641 2v4M17 13l-3.4641-2M17 13v4l-7.00001 4M17 13V9m0 4-7.00001 4m3.53591-6L10.5 12.7348M9.99999 21l-3.4641-2.1318M9.99999 21v-4m-3.4641 2v-.1318m0 0V15L10.5 12.7348m-3.96411 6.1334L3.5 17V5m0 0L7 3l3.5 2m-7 0 2.99999 2M10.5 5v7.7348M10.5 5 6.49999 7M17 9l3.5-2M17 9l-3.5-2M9.99999 17l-3.5-2m0 .5V7" />
</svg>
            </div>
          <h4 className="text-xl font-semibold mb-2">FDSS Individual Signer software</h4>
          <p className="text-gray-600">
          User-friendly, personalized, high throughput, needs DSC token, lacks server features.
          </p>
        
          <button
            onClick={() =>
              openModal(
                <div>
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="m17 13 3.4641-2V7L17 5l-3.4641 2v4M17 13l-3.4641-2M17 13v4l-7.00001 4M17 13V9m0 4-7.00001 4m3.53591-6L10.5 12.7348M9.99999 21l-3.4641-2.1318M9.99999 21v-4m-3.4641 2v-.1318m0 0V15L10.5 12.7348m-3.96411 6.1334L3.5 17V5m0 0L7 3l3.5 2m-7 0 2.99999 2M10.5 5v7.7348M10.5 5 6.49999 7M17 9l3.5-2M17 9l-3.5-2M9.99999 17l-3.5-2m0 .5V7" />
</svg>
                  <h3 className="text-2xl font-semibold text-gray-900 text-center">FDSS Individual Signer software</h3>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>User-Friendly Interface:</span> Easy to navigate and operate.
                  </p>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Personalized Signing:</span>Each user can apply their own DSC thumbprint to the document.</p>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Standalone Operation:</span> No need for integration with existing ERP or invoicing systems.
                  </p>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Cost-Effective:</span> One of the most economical solutions available.
                  </p>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Local Installation:</span> Install on a local machine and choose any file or folder for signing.
                  </p>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>High Throughput:</span> No limit on the number of documents that can be signed, with the
                  capability to process approximately 100 documents per minute.
                  </p>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>DSC Token Requirement:</span> The DSC token must be connected during the signing process.
                  </p>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>No Server Needed:</span> No server required, and no audit trails, logs, or reports are generated.
                  </p>
                  
                </div>
              )
            }
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
            Read more
          </button>
        </div>

        {/* Key Features Section */}
        <div className="p-6 bg-green-100 rounded-lg text-center w-1/2 ">
        <div className="text-green-500 mb-4 ">
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                  </svg>
                </div>
          <h4 className="text-xl font-semibold mb-2">FDSS Signer Pro</h4>
          <p className="text-gray-600">
          streamlines the bulk signing of
          documents, whether done manually or automatically.
          </p>
        
          <button
            onClick={() =>
              openModal(
                <div className=" p-6 rounded-lg ">
                <div className='mt-10'>
                <div className="text-green-500 mb-4 ">
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                  </svg>
                </div>
                <h3 className="text-2xl font-semibold text-gray-900 text-center">FDSS Signer Pro</h3>
                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Software Integration:</span> Allows for integration with existing invoicing systems through
                  command line integration.
                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Local Installation:</span> Set up on a local machine with configured input and output folders.
                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Automated Folder Monitoring:</span>Unsigned PDFs are placed in the input folder, automatically
                  signed, and then moved to the output folder.</p>
                
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>DSC Token Requirement:</span> Only registered the DSC token must be connected during the
                  document signing process.</p>
                  <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Automatic Emailing:</span> Signed documents can be automatically emailed to recipients.</p>
                  <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Autonomous Signing for Non-API Applications:</span> Applications that cannot use REST APIs can
                  be configured for automatic signing via our folder monitoring tool.</p>
                  <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>High Throughput:</span> Capable of signing up to 1,000 documents per hour.</p>
                  <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>No Server Needed:</span> No server required, and no audit trails, logs, or reports are generated.</p>
                
                </div>
                </div>
              )
            }
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
            Read more
          </button>
        </div>
      </div>

      {/* Modal Component */}
      <Modal isOpen={isModalOpen} closeModal={closeModal} content={modalContent} />
    </div>
    </div>
  );
};

export default Digitalsignature;


