import React, { useState, useEffect } from 'react';
import Sign from './services/consentmanager.jpg';

// Modal Component
const Modal = ({ isOpen, closeModal, content }) => {
  useEffect(() => {
    if (isOpen) {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling
      document.body.style.overflow = 'auto';
    }

    // Cleanup to ensure scrolling is restored if the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-4xl h-auto">
        <button
          onClick={closeModal}
          className="bg-red-500 text-white px-4 py-2 mr-5 mt-10 rounded float-right">
          Close
        </button>
        <div className="mt-4">{content}</div>
      </div>
    </div>
  );
};

const Digitalsignature = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-sky-50 flex flex-col items-center">
      <div className="max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
        <div className="lg:flex lg:justify-between lg:items-center">
          <div className="lg:w-2/3">
            <h1 className="text-7xl font-bold text-gray-900">
            Consent made easy, compliance ensured
            </h1>
            <p className="mt-6 text-3xl text-gray-700">
            Our Consent Manager is a versatile solution for businesses of all sizes, from e-commerce platforms and SaaS providers to healthcare organizations and more.
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:w-1/3">
            <div className="relative">
              <img className="rounded-lg shadow-lg ml-10" src={Sign} alt="Digital Signature" />
            </div>
          </div>
        </div>

        {/* Statistics */}
        <div className="m-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          
        </div>

        {/* Benefits Section */}
        <div className="flex justify-center gap-10">
        
        
        <div className="p-6 bg-blue-100 rounded-lg text-center w-1/2">
        <div className="text-blue-500 mb-4">
        <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M5.005 11.19V12l6.998 4.042L19 12v-.81M5 16.15v.81L11.997 21l6.998-4.042v-.81M12.003 3 5.005 7.042l6.998 4.042L19 7.042 12.003 3Z" />
</svg>
            </div>
          <h4 className="text-xl font-semibold mb-2">Key features</h4>
          <p className="text-gray-600">
          centralizes consent management, offers customizable forms, provides granular options, and maintains detailed audit trails for efficient compliance.
          </p>
        
          <button
            onClick={() =>
              openModal(
                <div>
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M5.005 11.19V12l6.998 4.042L19 12v-.81M5 16.15v.81L11.997 21l6.998-4.042v-.81M12.003 3 5.005 7.042l6.998 4.042L19 7.042 12.003 3Z" />
</svg>
                  <h3 className="text-2xl font-semibold text-gray-900 text-center">Key Features</h3>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Centralized Consent Management:</span> Easily collect and store consent from users in
one secure location, providing a comprehensive overview of consent status across all
platforms.
                </p>

                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Customizable Consent Forms:</span> Create tailored consent forms that align with your
branding and specific data collection needs, ensuring clarity and transparency for
users.
                </p>
                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Granular Consent Options:</span> Allow users to provide consent for specific data uses,
                  giving them greater control and improving their trust in your organization.
                </p>
                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Automated Audit Trails:</span> Maintain detailed logs of consent actions and changes,
                  ensuring you have a complete history for compliance audits and regulatory inquiries.
                </p>
                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>User-Friendly Dashboard:</span> Integrating digital signing into workflows automates the document
lifecycle from creation to approval and signing. This reduces manual processing time and speeds
up decision-making.
                </p>
                  
                </div>
              )
            }
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
            Read more
          </button>
        </div>

        {/* Key Features Section */}
        <div className="p-6 bg-green-100 rounded-lg text-center w-1/2 ">
        <div className="text-green-500 mb-4 ">
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                  </svg>
                </div>
          <h4 className="text-xl font-semibold mb-2">Benefits</h4>
          <p className="text-gray-600">
          enhances compliance, fosters user trust, and streamlines operations through automation and real-time reporting.
          </p>
        
          <button
            onClick={() =>
              openModal(
                <div className=" p-6 rounded-lg ">
                <div className='mt-10'>
                <div className="text-green-500 mb-4 ">
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                  </svg>
                </div>
                <h3 className="text-2xl font-semibold text-gray-900 text-center">Benefits</h3>
                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Enhanced Compliance:</span> Stay ahead of evolving data protection regulations with
                  automated updates and compliance checks, reducing the risk of penalties.
                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Increased User Trust:</span> Foster a positive relationship with your users by providing
                  transparent consent options, which can enhance customer loyalty and satisfaction.
                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Streamlined Operations:</span> Automate consent management processes, reducing
                  manual workloads and increasing efficiency across your organization.</p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Real-Time Reporting:</span> Access up-to-date reports on consent status, user preferences,
                  and compliance metrics, enabling informed decision-making.
                </p>
                
                </div>
                </div>
              )
            }
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
            Read more
          </button>
        </div>
      </div>

      {/* Modal Component */}
      <Modal isOpen={isModalOpen} closeModal={closeModal} content={modalContent} />
    </div>
    </div>
  );
};

export default Digitalsignature;

