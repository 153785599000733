import React, { useState, useEffect } from 'react';
import Sign from './services/digitalsignaturecerti.jpg';
import img15 from "./Logos/VSIGN.jpg";
import img16 from "./Logos/NCODE.jpg";
import img17 from "./Logos/EMUDHRA.jpg";
import img18 from "./Logos/XTRATRUST.jpg";
import img19 from "./Logos/ID SIGN.jpg";

// Modal Component
const Modal = ({ isOpen, closeModal, content }) => {
  useEffect(() => {
    if (isOpen) {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling
      document.body.style.overflow = 'auto';
    }

    // Cleanup to ensure scrolling is restored if the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-4xl h-auto">
        <button
          onClick={closeModal}
          className="bg-red-500 text-white px-4 py-2 mr-5 mt-10 rounded float-right">
          Close
        </button>
        <div className="mt-4">{content}</div>
      </div>
    </div>
  );
};

const Digitalsignature = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-sky-50 flex flex-col items-center">
      <div className="max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
        <div className="lg:flex lg:justify-between lg:items-center">
          <div className="lg:w-2/3">
            <h1 className="text-7xl font-bold text-gray-900">
              Authenticity, integrity, and non-repudiation in every digital transaction
            </h1>
            <p className="mt-6 text-3xl text-gray-700">
              Ensure authenticity, integrity, and non-repudiation of digital documents. They offer legal validity, enhanced security, and streamline processes, saving time and costs. They integrate seamlessly with various digital systems.
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:w-1/3">
            <div className="relative">
              <img className="rounded-lg shadow-lg" src={Sign} alt="Digital Signature" />
            </div>
          </div>
        </div>

        {/* Statistics */}
        <div className="font-bold text-3xl text-center mt-20">Partners with</div>
    <div className="flex justify-between items-center p-6 mx-auto w-full max-w-screen-xl mb-20">
  <img src={img15} alt="Company 1" className="h-24 w-36" />
  <img src={img16} alt="Company 2" className="h-24 w-36" />
  <img src={img17} alt="Company 3" className="h-24 w-36" />
  <img src={img18} alt="Company 4" className="h-24 w-36" />
  <img src={img19} alt="Company 5" className="h-24 w-36" />
</div>

        {/* Benefits Section */}
        <div className="flex justify-center gap-10">


          <div className="p-6 bg-blue-100 rounded-lg text-center w-1/2">
            <div className="text-blue-500 mb-4">
            <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.032 12l1.984 1.984 4.96-4.96m4.55 5.272.893-.893a1.984 1.984 0 0 0 0-2.806l-.893-.893a1.984 1.984 0 0 1-.581-1.403V7.04a1.984 1.984 0 0 0-1.984-1.984h-1.262a1.983 1.983 0 0 1-1.403-.581l-.893-.893a1.984 1.984 0 0 0-2.806 0l-.893.893a1.984 1.984 0 0 1-1.403.581H7.04A1.984 1.984 0 0 0 5.055 7.04v1.262c0 .527-.209 1.031-.581 1.403l-.893.893a1.984 1.984 0 0 0 0 2.806l.893.893c.372.372.581.876.581 1.403v1.262a1.984 1.984 0 0 0 1.984 1.984h1.262c.527 0 1.031.209 1.403.581l.893.893a1.984 1.984 0 0 0 2.806 0l.893-.893a1.985 1.985 0 0 1 1.403-.581h1.262a1.984 1.984 0 0 0 1.984-1.984V15.7c0-.527.209-1.031.581-1.403Z"/>
</svg>
            </div>
            <h4 className="text-xl font-semibold mb-2">DSC Types</h4>
            <p className="text-gray-600">
              Individual DSCs for personal use, organizational DSCs for company documents.
            </p>

            <button
              onClick={() =>
                openModal(
                  <div>
                    <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.032 12l1.984 1.984 4.96-4.96m4.55 5.272.893-.893a1.984 1.984 0 0 0 0-2.806l-.893-.893a1.984 1.984 0 0 1-.581-1.403V7.04a1.984 1.984 0 0 0-1.984-1.984h-1.262a1.983 1.983 0 0 1-1.403-.581l-.893-.893a1.984 1.984 0 0 0-2.806 0l-.893.893a1.984 1.984 0 0 1-1.403.581H7.04A1.984 1.984 0 0 0 5.055 7.04v1.262c0 .527-.209 1.031-.581 1.403l-.893.893a1.984 1.984 0 0 0 0 2.806l.893.893c.372.372.581.876.581 1.403v1.262a1.984 1.984 0 0 0 1.984 1.984h1.262c.527 0 1.031.209 1.403.581l.893.893a1.984 1.984 0 0 0 2.806 0l.893-.893a1.985 1.985 0 0 1 1.403-.581h1.262a1.984 1.984 0 0 0 1.984-1.984V15.7c0-.527.209-1.031.581-1.403Z"/>
</svg>
                    <h3 className="text-2xl font-semibold text-gray-900 text-center ">DSC Types</h3>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Personal Digital Signature Certificate:</span> Used for personal
                      digital transactions, secure email communications, and personal document signing.
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Organizational Digital Signature Certificate:</span>Used to sign documents on behalf of the organization, such as contracts,
                      agreements, and regulatory filings. It can be used by authorized representatives of
                      the organization.</p>


                  </div>
                )
              }
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
              Read more
            </button>
          </div>

          {/* Key Features Section */}
          <div className="p-6 bg-green-100 rounded-lg text-center w-1/2 ">
            <div className="text-green-500 mb-4 ">
              <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
              </svg>
            </div>
            <h4 className="text-xl font-semibold mb-2">Different Classes of DSC</h4>
            <p className="text-gray-600">
              DSCs are categorized by security level. Class 1 is for basic identity verification, Class 2 for moderate verification, and Class 3 for high-security transactions.
            </p>

            <button
              onClick={() =>
                openModal(
                  <div className=" p-6 rounded-lg ">
                    <div className='mt-10'>
                      <div className="text-green-500 mb-4 ">
                        <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                        </svg>
                      </div>
                      <h3 className="text-2xl font-semibold text-gray-900 text-center">Different Classes of DSC</h3>
                      <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>Class 1 DSC:</span> Used for individual users to verify the identity of email address holders.
                        Provides assurance that the individual is who they claim to be, primarily used for email
                        security and non-financial transactions.
                      </p>
                      <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>Class 2 DSC:</span> Used for individuals or organizations where identity verification is required to
                        a moderate level. Verifies that the information in the certificate matches the information
                        provided by the certificate holder. Commonly used for a wide range of online transactions
                        and document signing.
                      </p>
                      <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>Class 3 DSC:</span>Provides the highest level of security and is used for sensitive and high-value
                        transactions. Requires the certificate holder to present themselves in person/virtually to a
                        Registration Authority (RA) for verification. Suitable for high-risk and critical business
                        transactions.</p>

                    </div>
                  </div>
                )
              }
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
              Read more
            </button>
          </div>
        </div>

        {/* Modal Component */}
        <Modal isOpen={isModalOpen} closeModal={closeModal} content={modalContent} />
      </div>
    </div>
  );
};

export default Digitalsignature;


