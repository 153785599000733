import React, { useEffect, useRef } from 'react';
import img1 from "./Logos/1.png";
import img2 from "./Logos/ixigo.png";
import img3 from "./Logos/ITH.png";
import img4 from "./Logos/kfintech.jpeg";
import img5 from "./Logos/toyo_ink.png";
import img6 from "./Logos/secure.png";
import img7 from "./Logos/kumi.png";
import img8 from "./Logos/colourtex.png";
import img9 from "./Logos/morning_star.png";
import img10 from "./Logos/MOL.png";
import img11 from "./Logos/NFIL.png";
import img12 from "./Logos/borosil.png";
import img13 from "./Logos/jindal_india_limied_logo.jpeg";
import img14 from "./Logos/lg.svg";

const images = [
  { imageSrc: img1 },
  { imageSrc: img2 },
  { imageSrc: img3 },
  { imageSrc: img4 },
  { imageSrc: img5 },
  { imageSrc: img6 },
  { imageSrc: img7 },
  { imageSrc: img8 },
  { imageSrc: img9 },
  { imageSrc: img10 },
  { imageSrc: img11 },
  { imageSrc: img12 },
  { imageSrc: img13 },
  { imageSrc: img14 },
  // Repeat as needed...
];

const ImageScroller = () => {
  const scrollRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  useEffect(() => {
    const startAutoScroll = () => {
      scrollIntervalRef.current = setInterval(() => {
        if (scrollRef.current) {
          const maxScrollLeft = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
          if (scrollRef.current.scrollLeft >= maxScrollLeft) {
            scrollRef.current.scrollTo({ left: 0, behavior: 'smooth' });
          } else {
            scrollRef.current.scrollBy({ left: 400, behavior: 'smooth' });
          }
        }
      }, 1500); // Adjust the interval duration as needed
    };

    startAutoScroll();

    return () => clearInterval(scrollIntervalRef.current);
  }, []);

  return (
    <div className="bg-white p-8" id='customers'>
      <div className="font-bold text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-center mt-20 mb-8">
        Our Clients
      </div>
      <div
        ref={scrollRef}
        className="flex overflow-x-auto space-x-12 sm:space-x-16 md:space-x-20 lg:space-x-24 pb-8 scrollbar-hide"
        style={{ scrollBehavior: 'smooth' }}
      >
        {images.map((image, index) => (
          <div 
            key={index} 
            className="flex-shrink-0 w-1/3 sm:w-1/4 md:w-1/5 lg:w-1/6 xl:w-[10%] px-2"
          >
            <img
              src={image.imageSrc}
              alt={`Logo ${index + 1}`}
              className="rounded-lg max-w-full h-[100px] sm:h-[120px] md:h-[150px] lg:h-[200px] object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageScroller;