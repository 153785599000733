import React from 'react'

function Home() {
  return (
    <>
      <div className="bg-gray-50 flex items-center justify-center px-8 sm:px-12 md:px-16 lg:px-24" id='home'>
        {/* Floating animated blobs */}
        <div className="absolute top-4 -left-4 w-40 h-40 sm:w-60 sm:h-60 md:w-72 md:h-72 bg-blue-300 rounded-xl mix-blend-multiply filter blur-xl opacity-70 animate-blob" />
        <div className="absolute top-4 w-48 h-48 sm:w-80 sm:h-80 md:w-96 md:h-96 bg-purple-400 rounded-xl mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000" />
        
        {/* Main content container */}
        <div className="wrap-container p-4 sm:p-5 md:p-8 rounded-lg flex items-center justify-between space-x-4 sm:space-x-8">
          <div className="flex-1">
            <div className="font-bold text-4xl sm:text-6xl md:text-8xl lg:text-9xl text-center mt-16 sm:mt-24 md:mt-32">
              Combining
            </div>
            <p className="text-lg sm:text-2xl md:text-3xl lg:text-5xl mt-2 mb-16 sm:mb-24 md:mb-32">
              Operational Excellence with Deep Domain Expertise
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home