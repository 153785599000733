import React, { useState, useEffect } from 'react';
import Sign from './services/integratedsol.jpg';

// Modal Component
const Modal = ({ isOpen, closeModal, content }) => {
  useEffect(() => {
    if (isOpen) {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling
      document.body.style.overflow = 'auto';
    }

    // Cleanup to ensure scrolling is restored if the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-4xl h-auto">
        <button
          onClick={closeModal}
          className="bg-red-500 text-white px-4 py-2 mr-5 mt-10 rounded float-right">
          Close
        </button>
        <div className="mt-4">{content}</div>
      </div>
    </div>
  );
};

const Digitalsignature = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-sky-50 flex flex-col items-center">
      <div className="max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
        <div className="lg:flex lg:justify-between lg:items-center">
          <div className="lg:w-2/3">
            <h1 className="text-7xl font-bold text-gray-900">
              Your digital handshake for the world
            </h1>
            <p className="mt-6 text-3xl text-gray-700">
              For companies requiring document signing, and
              across multiple geographic locations, along with detailed logs and audit
              trail reports, we offer Integrated Signer Solutions.
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:w-1/3">
            <div className="relative">
              <img className="rounded-lg shadow-lg ml-10" src={Sign} alt="Digital Signature" />
            </div>
          </div>
        </div>

        {/* Statistics */}
        <div className="m-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">

        </div>

        {/* Benefits Section */}
        <div className="flex justify-center gap-10">


          <div className="p-6 bg-blue-100 rounded-lg text-center w-1/2">
            <div className="text-blue-500 mb-4">
            <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M5 3a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm0 12a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H5Zm12 0a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-2Zm0-12a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-2Z" />
  <path strokeLinecap="round" strokeLinejoin="round" fillRule="evenodd" d="M10 6.5a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1ZM10 18a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Zm-4-4a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1Zm12 0a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1Z" clipRule="evenodd" />
</svg>
            </div>
            <h4 className="text-xl font-semibold mb-2">On-Premise Signing Solutions</h4>
            <p className="text-gray-600">
              Server-based signing solution with API integration, supports unlimited approvers, and offers high throughput and comprehensive reporting
            </p>

            <button
              onClick={() =>
                openModal(
                  <div>
                    <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M5 3a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm0 12a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H5Zm12 0a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-2Zm0-12a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-2Z" />
  <path strokeLinecap="round" strokeLinejoin="round" fillRule="evenodd" d="M10 6.5a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1ZM10 18a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Zm-4-4a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1Zm12 0a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1Z" clipRule="evenodd" />
</svg>
                    <h3 className="text-2xl font-semibold text-gray-900 text-center ">On-Premise Signing Solutions</h3>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Server Installation:</span> The Signer Appliance (SA) is installed on client’s physical server/VPC.
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>API Integration:</span>ERP/CRM systems like SAP, Oracle, Salesforce, or any other can integrate
                      via APIs for automatic document signing.
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Document Signing Options:</span> Organisational Document Signer Certificates (Server Certificate):
                      No need for DSC connections; the document signer PFX file is encrypted and stored on the
                      signature server.
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Unlimited Approvers:</span> There is no limit on the number of approvers.
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Cost and Time Savings:</span> Reduces the need for paper prints, handling, and document
                      archiving.
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>HSM Box Support:</span> Supports signatures stored in an HSM box.
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Sample Code:</span> Provided in ABAP, JAVA, .NET, and PHP and various other languages
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>High Throughput:</span> Capable of signing up to 500 documents per minute.
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Comprehensive Reporting:</span> All audit trails, logs, and reports are available on the server.
                    </p>

                  </div>
                )
              }
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
              Read more
            </button>
          </div>

          {/* Key Features Section */}
          <div className="p-6 bg-green-100 rounded-lg text-center w-1/2 ">
            <div className="text-green-500 mb-4 ">
              <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
              </svg>
            </div>
            <h4 className="text-xl font-semibold mb-2">Cloud hosted Signing Solutions</h4>
            <p className="text-gray-600">
              offers flexibility, reduced costs, and high performance, but relies on internet connectivity for optimal operation.
            </p>

            <button
              onClick={() =>
                openModal(
                  <div className=" p-6 rounded-lg ">
                    <div className='mt-10'>
                      <div className="text-green-500 mb-4 ">
                        <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                        </svg>
                      </div>
                      <h3 className="text-2xl font-semibold text-gray-900 text-center">Cloud hosted Signing Solutions</h3>
                      <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>Cloud Installation:</span> The Signer Appliance is installed on our cloud server instead of on-
                        premise and offered as a hassle free annual subscription.
                      </p>
                      <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>Document Signing Options:</span> Supports Organisational Document Signer Certificates and HSM
                        based signatures
                      </p>
                      <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>Document Archiving:</span>Documents can archived from the cloud every 15 days.</p>
                      <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>HSM Box Support:</span> Supports signatures stored in an HSM box.</p>
                      <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>Cost and Time Savings:</span> Eliminates the costs and time associated with paper prints, handling,
                        and document archiving.</p>
                      <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>Sample Code:</span> Provided in ABAP, JAVA, .NET, and PHP and various other languages</p>
                      <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>High Throughput:</span> Capable of signing up to 500 documents per minute.</p>
                      <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>Internet Dependency:</span> Performance depends on internet bandwidth speed.</p>
                      <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>Comprehensive Reporting:</span> All audit trails, logs, and reports are available on the server.</p>

                    </div>
                  </div>
                )
              }
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
              Read more
            </button>
          </div>
        </div>

        {/* Modal Component */}
        <Modal isOpen={isModalOpen} closeModal={closeModal} content={modalContent} />
      </div>
    </div>
  );
};

export default Digitalsignature;


