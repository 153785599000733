import React, { useState, useEffect } from 'react';
import Sign from './services/sslcertificates.jpg';

// Modal Component
const Modal = ({ isOpen, closeModal, content }) => {
  useEffect(() => {
    if (isOpen) {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling
      document.body.style.overflow = 'auto';
    }

    // Cleanup to ensure scrolling is restored if the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-4xl h-auto">
        <button
          onClick={closeModal}
          className="bg-red-500 text-white px-4 py-2 mr-5 mt-10 rounded float-right">
          Close
        </button>
        <div className="mt-4">{content}</div>
      </div>
    </div>
  );
};

const Digitalsignature = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-sky-50 flex flex-col items-center">
      <div className="max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
        <div className="lg:flex lg:justify-between lg:items-center">
          <div className="lg:w-2/3">
            <h1 className="text-7xl font-bold text-gray-900">
            Protect your customers, protect your brand.
            </h1>
            <p className="mt-6 text-3xl text-gray-700">
            SSL certificates secure data transmission between users and web servers, verifying the website's identity and encrypting data. This ensures secure online transactions and protects user privacy.
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:w-1/3">
            <div className="relative">
              <img className="rounded-lg shadow-lg ml-10" src={Sign} alt="Digital Signature" />
            </div>
          </div>
        </div>

        {/* Statistics */}
        <div className="m-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          
        </div>

        {/* Benefits Section */}
        <div className="flex justify-center gap-10">
        
        
        <div className="p-6 bg-blue-100 rounded-lg text-center w-1/2">
        <div className="text-blue-500 mb-4">
        <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961" />
</svg>
            </div>
          <h4 className="text-xl font-semibold mb-2">Certificate Validation Levels</h4>
          <p className="text-gray-600">
          EV certificates validate organizations, OV for intermediate, DV for basic domain verification.
          </p>
        
          <button
            onClick={() =>
              openModal(
                <div>
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961" />
</svg>
                  <h3 className="text-2xl font-semibold text-gray-900 text-center">Certificate Validation Levels</h3>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Extended Validation (EV) Certificates:</span> Provide the highest level of validation and trust,
                  displaying the organization’s name in the browser’s address bar.
                  </p>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Organization Validated (OV) Certificates:</span>Offer a higher level of validation than domain-
                  validated certificates by verifying the organization’s details. 
                  </p>
                  <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Domain Validated (DV) Certificates:</span> Ensure that the domain is owned by the requester,
                  suitable for basic encryption needs.
                  </p>
                  
                </div>
              )
            }
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
            Read more
          </button>
        </div>

        {/* Key Features Section */}
        <div className="p-6 bg-green-100 rounded-lg text-center w-1/2 ">
        <div className="text-green-500 mb-4 ">
                  <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                  </svg>
                </div>
          <h4 className="text-xl font-semibold mb-2">Certificate Types</h4>
          <p className="text-gray-600">
          Secure one domain with a Single certificate,  Wildcard covers all subdomains,  Multi-Domain protects multiple domains, and UCC secures for communication (like email)
          </p>
        
          <button
            onClick={() =>
              openModal(
                <div className=" p-6 rounded-lg ">
                <div className='mt-10'>
                <svg className="h-12 w-12 mx-auto" fill="none" stroke="green" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                  </svg>
                
                <h3 className="text-2xl font-semibold text-gray-900 text-center">Certificate Types</h3>
                <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Single Domain SSL Certificate:</span> Secures a single domain or subdomain.
                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Wildcard SSL Certificate:</span> Secures a primary domain and all its subdomains (e.g.,
                    *.example.com).
                </p>
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Multi-Domain SSL Certificate (MDC):</span>Protects multiple domains under a single certificate.</p>
                
                <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                  <span className='font-bold'>Unified Communications Certificate (UCC):</span> Designed to secure multiple domain names,
                  often used in Exchange environments.</p>
                
                </div>
                </div>
              )
            }
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
            Read more
          </button>
        </div>
      </div>

      {/* Modal Component */}
      <Modal isOpen={isModalOpen} closeModal={closeModal} content={modalContent} />
    </div>
    </div>
  );
};

export default Digitalsignature;


