import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const FeatureBox = ({ icon, title, description, subFeatures, isHovered, handleMouseEnter, handleMouseLeave, fixedHeight }) => (
  <div
    className={`relative w-full sm:w-[300px] md:w-[350px] lg:w-[400px] overflow-hidden rounded-lg transition-all duration-500 
      ${isHovered && !fixedHeight ? 'h-[900px]' : 'h-[300px]'}`}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
    {/* Main Box */}
    <div className="bg-slate-900 mix-blend-screen shadow-inherit shadow-inner p-6 w-full h-[200px] rounded-lg text-center text-white cursor-pointer hover:bg-gray-700 transition-all duration-500 flex-1 ">
      <div className="text-3xl mb-2">{icon}</div>
      <h3 className="text-xl md:text-2xl font-semibold">{title}</h3>
      <p className="text-lg md:text-xl mt-2">{description}</p>
    </div>

    {/* Sub-features (shown on hover) */}
    {subFeatures.length > 0 && (
      <div
        className={`absolute left-0 top-[250px] w-full flex flex-col space-y-4 transition-all duration-500 ${isHovered ? 'opacity-100' : 'opacity-0'}`}
      >
        {subFeatures.map((subFeature, index) => {
          let linkPath = '';
          switch (subFeature.title) {
            case 'Document Signer Certificates':
              linkPath = '/documentSigner';
              break;
            case 'Digital Signature Certificate':
              linkPath = '/digitalsignCert';
              break;
            case 'Integrated Solutions':
              linkPath = '/integratedSoln';
              break;
            case 'Non-Integrated Solutions':
              linkPath = '/nonintegratedSoln';
              break;
            case 'Web Signer Solutions':
              linkPath = '/websignersoln';
              break;
            case 'Digital Sign Verifier':
              linkPath = '/digitalSignVerify';
              break;
            case 'SSL certificates':
              linkPath = '/sslCert';
              break;
            default:
              linkPath = '/';
          }

          return (
            <Link to={linkPath} key={index}>
              <div className="bg-gray-800 p-6 rounded-lg text-center text-white cursor-pointer transition-all duration-500 hover:bg-gray-700">
                <div className="text-3xl mb-2">{subFeature.icon}</div>
                <h3 className="text-lg md:text-xl font-semibold">{subFeature.title}</h3>
                <p className="text-sm mt-2">{subFeature.description}</p>
              </div>
            </Link>
          );
        })}
      </div>
    )}
  </div>
);

const Features = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const featuresData = [
    {
      icon: '🔏',
      title: 'Digital Signatures',
      description: 'Authenticity, integrity, and non-repudiation in every digital transaction.',
      subFeatures: [
        { icon: '📂', title: 'Document Signer Certificates', description: 'Your documents, authenticated with trust—Document Signer Certificates for every critical signature' },
        { icon: '✒︎', title: 'Digital Signature Certificate', description: 'Digitally secure, financially sound—Digital Signature Certificates for seamless transactions.' },
      ],
    },
    {
      icon: '🖋️',
      title: 'Signing Solutions',
      description: 'Where security meets simplicity, sealing your success with every signature.',
      subFeatures: [
        { icon: '⛓️', title: 'Integrated Solutions', description: 'Integrated solutions—where your financial processes meet efficiency and control' },
        { icon: '🌍', title: 'Non-Integrated Solutions', description: 'Freedom to customize—Non-Integrated Solutions built around your financial needs' },
        { icon: '🔗', title: 'Web Signer Solutions', description: 'Sign anywhere, anytime—Web Signer Solutions to keep your financial operations moving' },
      ],
    },
  ];

  return (
    <>
      <div className='flex flex-col sm:flex-row flex-wrap justify-center sm:justify-between px-6 py-4 gap-4'>
        {featuresData.map((feature, index) => (
          <FeatureBox
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
            subFeatures={feature.subFeatures}
            isHovered={hoveredIndex === index}
            handleMouseEnter={() => setHoveredIndex(index)}
            handleMouseLeave={() => setHoveredIndex(null)}
          />
        ))}

        {/* Link only the Digital Sign Verifier box */}
        <Link to="/digitalSignVerify">
          <FeatureBox
            icon="🛠️"
            title="Digital Sign Verifier"
            description="Ensuring every signature is more than just a mark, it's a guarantee."
            subFeatures={[]} // No sub-features for this box
            isHovered={hoveredIndex === featuresData.length}
            handleMouseEnter={() => setHoveredIndex(featuresData.length)}
            handleMouseLeave={() => setHoveredIndex(null)}
            fixedHeight // This prop will keep the height fixed
          />
        </Link>
      </div>

      <div className='flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-8 px-6 py-4'>
        <Link to='consentmanager'>
          <div className="bg-slate-900 mix-blend-screen shadow-inherit shadow-inner p-6 w-full sm:w-[300px] md:w-[350px] lg:w-[400px] h-[200px] rounded-lg text-center text-white cursor-pointer hover:bg-gray-700 transition-all duration-500 ">
            <div className="text-3xl mb-2">🔩</div>
            <h3 className="text-xl md:text-2xl font-semibold">Consent Manager</h3>
            <p className="text-lg md:text-xl mt-2">Stay ahead of the curve with our cutting-edge consent manager.</p>
          </div>
        </Link>
        <Link to='amlservices'>
          <div className="bg-slate-900 mix-blend-screen shadow-inherit shadow-inner p-6 w-full sm:w-[300px] md:w-[350px] lg:w-[400px] h-[200px] rounded-lg text-center text-white cursor-pointer hover:bg-gray-700 transition-all duration-500 ">
            <div className="text-3xl mb-2">💸</div>
            <h3 className="text-xl md:text-2xl font-semibold">AML Services</h3>
            <p className="text-lg md:text-xl mt-2">Bolster your compliance framework and enhance risk management with our AML integration.</p>
          </div>
        </Link>
        <Link to='/sslCert'>
          <div className="bg-slate-900 mix-blend-screen shadow-inherit shadow-inner p-6 w-full sm:w-[300px] md:w-[350px] lg:w-[400px] h-[200px] rounded-lg text-center text-white cursor-pointer hover:bg-gray-700 transition-all duration-500 ">
            <div className="text-3xl mb-2">📑</div>
            <h3 className="text-xl md:text-2xl font-semibold">SSL certificates</h3>
            <p className="text-lg md:text-xl mt-2">Keep your financial data safe—SSL Certificates that build trust one encrypted transaction at a time.</p>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Features;