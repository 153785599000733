import React from 'react';
import './dropdown2.css';
import { Link } from 'react-router-dom';

const Dropdown2 = () => {
  const cards = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 10h16M4 14h16M4 18h16"
          />
        </svg>
      ),
      title: "Blogs",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8 6h13M8 12h9M8 18h5M3 6h.01M3 12h.01M3 18h.01"
          />
        </svg>
      ),
      title: "Case Studies",
    },
  ];
  return (
    <div className="dropdown-menu">
      <div className='blog'>
      <div className="flex flex-col   rounded-lg ">
      {cards.map((card, index) => (
        <div
          key={index}
          className="flex items-center space-x-4 p-4 bg-white rounded-xl hover:bg-gray-200 "
        >
          <div className="bg-blue-100 text-blue-600 p-3 rounded-full">
            {card.icon}
          </div>
          <h2 className="text-lg font-semibold text-gray-800 ">{card.title}</h2>
        </div>
      ))}
    </div>
      </div>
    </div>
  );
};

export default Dropdown2;
