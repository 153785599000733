import React, { useState, useEffect } from 'react';
import Sign from './services/singing.jpg';

// Modal Component
const Modal = ({ isOpen, closeModal, content }) => {
  useEffect(() => {
    if (isOpen) {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling
      document.body.style.overflow = 'auto';
    }

    // Cleanup to ensure scrolling is restored if the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-4xl h-auto">
        <button
          onClick={closeModal}
          className="bg-red-500 text-white px-4 py-2 mr-5 mt-10 rounded float-right">
          Close
        </button>
        <div className="mt-4">{content}</div>
      </div>
    </div>
  );
};

const Digitalsignature = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-sky-50 flex flex-col items-center">
      <div className="max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
        <div className="lg:flex lg:justify-between lg:items-center">
          <div className="lg:w-2/3">
            <h1 className="text-7xl font-bold text-gray-900">
              Streamline your approvals, secure your documents
            </h1>
            <p className="mt-6 text-3xl text-gray-700">
              Document Signer Certificates automate document signing for organizations, ensuring integrity and authenticity. They're ideal for integrating with ERP systems to streamline workflows and enhance accountability.
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:w-1/3 ml-10">
            <div className="relative">
              <img className="rounded-lg shadow-lg" src={Sign} alt="Digital Signature" />
            </div>
          </div>
        </div>

        {/* Statistics */}
        <div className="m-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">

        </div>

        {/* Benefits Section */}
        <div className="flex justify-center gap-10">


          <div className="p-6 bg-blue-100 rounded-lg text-center w-1/2">
            <div className="text-blue-500 mb-4">
            <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8 7H5a2 2 0 0 0-2 2v4m5-6h8M8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m0 0h3a2 2 0 0 1 2 2v4m0 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6m18 0s-4 2-9 2-9-2-9-2m9-2h.01"/>
</svg>
            </div>
            <h4 className="text-xl font-semibold mb-2">Key Features</h4>
            <p className="text-gray-600">
              Organizational DSCs automate signing, ensuring accountability and integration with ERP systems.
            </p>

            <button
              onClick={() =>
                openModal(
                  <div>
                    <svg className="h-12 w-12 mx-auto" fill="none" stroke="blue" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8 7H5a2 2 0 0 0-2 2v4m5-6h8M8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m0 0h3a2 2 0 0 1 2 2v4m0 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6m18 0s-4 2-9 2-9-2-9-2m9-2h.01"/>
</svg>
                    <h3 className="text-2xl font-semibold text-gray-900 text-center ">Key features</h3>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Direct Integration:</span> Document Signer Certificates are intended for systems that
                      integrate directly with servers, enabling automatic signing of documents without
                      manual intervention.
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Automated Signing:</span>Primarily used for automated signing processes within ERP
                      systems, ensuring that documents are signed accurately and consistently.</p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Operational Efficiency:</span> This integration helps streamline workflows by automating
                      the signing process, reducing the need for manual handling and increasing
                      efficiency.
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Organizational Identity:</span> Unlike Individual Digital Signatures, which are issued to
                      individuals, Document Signer Certificates are issued to the organization’s legal
                      name. This reflects the organizational authority behind the document.
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Document Integrity:</span> Helps in maintaining the integrity of documents by applying
                      digital signatures that verify the authenticity and ensure that the documents have
                      not been altered after signing.
                    </p>
                    <p className="mt-4 text-xl text-gray-600 leading-relaxed">
                      <span className='font-bold'>Accountability:</span> By associating the digital signature with the organizational name,
                      these certificates underscore the organization's accountability for the signed
                      documents.
                    </p>

                  </div>
                )
              }
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
              Read more
            </button>
          </div>

          {/* Key Features Section */}
          <div className="p-6 bg-green-100 rounded-lg text-center w-1/2 ">
            <div className="text-green-500 mb-4 ">
              <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
              </svg>
            </div>
            <h4 className="text-xl font-semibold mb-2">Certificate Classes</h4>
            <p className="text-gray-600">
              Class 2: Moderate security, Class 3: High security.
            </p>

            <button
              onClick={() =>
                openModal(
                  <div className=" p-6 rounded-lg ">
                    <div className='mt-10'>
                      <div className="text-green-500 mb-4 ">
                        <svg className="h-12 w-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M4 4h16v16H4z" />
                        </svg>
                      </div>
                      <h3 className="text-2xl font-semibold text-gray-900 text-center">Certificate Classes</h3>

                      <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>Class 2 Certificates:</span> Suitable for scenarios where identity validation is important, but
                        the risk associated with the document’s integrity is moderate. Provides a good
                        balance between security and operational convenience.
                      </p>
                      <p className="mt-2 text-xl text-gray-600 leading-relaxed">
                        <span className='font-bold'>Class 3 Certificates:</span>Offer a higher level of assurance with more stringent validation
                        requirements. Ideal for environments where higher security and compliance
                        standards are necessary as these are downloaded in HSM devices only.</p>

                    </div>
                  </div>
                )
              }
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
              Read more
            </button>
          </div>
        </div>

        {/* Modal Component */}
        <Modal isOpen={isModalOpen} closeModal={closeModal} content={modalContent} />
      </div>
    </div>
  );
};

export default Digitalsignature;


