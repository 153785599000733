import React from 'react';
import './dropdown.css';
import { Link } from 'react-router-dom';

const Dropdown = () => {
  return (
    <ul className="dropdown-menu">
      <li className="dropdown-item">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="dropdown-icon">
          <path fillRule="evenodd" d="M2.25 5.25a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3V15a3 3 0 0 1-3 3h-3v.257c0 .597.237 1.17.659 1.591l.621.622a.75.75 0 0 1-.53 1.28h-9a.75.75 0 0 1-.53-1.28l.621-.622a2.25 2.25 0 0 0 .659-1.59V18h-3a3 3 0 0 1-3-3V5.25Zm1.5 0v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5Z" clipRule="evenodd" />
        </svg>
        <div className="dropdowncontent">
          <Link to="#" className="dropdown-links">Digital signatures</Link>
          <p className="dropdown-links">
            <Link to="/documentsigner">Document Signer Certificates</Link>
          </p>
          <p className="dropdown-links">
            <Link to="/digitalsignCert">Digital Signature Certificate</Link>
          </p>
        </div>
      </li>

      <li className="dropdown-item">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="dropdown-icon">
          <path fillRule="evenodd" d="M20.599 1.5c-.376 0-.743.111-1.055.32l-5.08 3.385a18.747 18.747 0 0 0-3.471 2.987 10.04 10.04 0 0 1 4.815 4.815 18.748 18.748 0 0 0 2.987-3.472l3.386-5.079A1.902 1.902 0 0 0 20.599 1.5Zm-8.3 14.025a18.76 18.76 0 0 0 1.896-1.207 8.026 8.026 0 0 0-4.513-4.513A18.75 18.75 0 0 0 8.475 11.7l-.278.5a5.26 5.26 0 0 1 3.601 3.602l.502-.278ZM6.75 13.5A3.75 3.75 0 0 0 3 17.25a1.5 1.5 0 0 1-1.601 1.497.75.75 0 0 0-.7 1.123 5.25 5.25 0 0 0 9.8-2.62 3.75 3.75 0 0 0-3.75-3.75Z" clipRule="evenodd" />
        </svg>
        <div className="dropdowncontent">
          <Link to="#" className="dropdown-links">Signing solutions</Link>
          <p className="dropdown-links">
            <Link to='/integratedSoln'>Integrated Solutions</Link>
          </p>
          <p className="dropdown-links">
            <Link to='/nonintegratedSoln'>Non-Integrated Solutions</Link>
          </p>
          <p className="dropdown-links">
            <Link to='/websignersoln'>Web Signer Solutions</Link>
          </p>
        </div>
      </li>

      {/* Last 4 centered items */}
      <li className="dropdown-item">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="dropdown-icon">
          <path fillRule="evenodd" d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
        </svg>
        <div className="dropdowncontent center-text">
          <p className="dropdown-links">
            <Link to="digitalSignVerify">Digital Signature Verifier</Link>
          </p>
        </div>
      </li>

      <li className="dropdown-item">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="dropdown-icon">
          <path d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 5h6m-6 4h6M10 3v4h4V3h-4Z" />
        </svg>
        <div className="dropdowncontent center-text">
          <p className="dropdown-links">
            <Link to="/sslCert">SSL Certificates</Link>
          </p>
        </div>
      </li>

      <li className="dropdown-item">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="dropdown-icon">
          <path fillRule="evenodd" d="M17 10v1.126c.367.095.714.24 1.032.428l.796-.797 1.415 1.415-.797.796c.188.318.333.665.428 1.032H21v2h-1.126c-.095.367-.24.714-.428 1.032l.797.796-1.415 1.415-.796-.797a3.979 3.979 0 0 1-1.032.428V20h-2v-1.126a3.977 3.977 0 0 1-1.032-.428l-.796.797-1.415-1.415.797-.796A3.975 3.975 0 0 1 12.126 16H11v-2h1.126c.095-.367.24-.714.428-1.032l-.797-.796 1.415-1.415.796.797A3.977 3.977 0 0 1 15 11.126V10h2Zm.406 3.578.016.016c.354.358.574.85.578 1.392v.028a2 2 0 0 1-3.409 1.406l-.01-.012a2 2 0 0 1 2.826-2.83ZM5 8a4 4 0 1 1 7.938.703 7.029 7.029 0 0 0-3.235 3.235A4 4 0 0 1 5 8Zm1.5 0A2.5 2.5 0 1 1 9 10.5 2.5 2.5 0 0 1 6.5 8Zm-.4 6.133c.34.085.691.142 1.05.166a7.022 7.022 0 0 0-.37 2.18v1.546c0 .506.268.973.7 1.226l2.1 1.212a3.5 3.5 0 0 0 3.52 0l2.1-1.212a1.4 1.4 0 0 0 .7-1.226v-1.545a7.05 7.05 0 0 0-.37-2.181 6 6 0 1 0-8.43 0Z" clipRule="evenodd" />
        </svg>
        <div className="dropdowncontent center-text">
          <p className="dropdown-links">
            <Link to="/consentManager">Consent Manager</Link>
          </p>
        </div>
      </li>

      <li className="dropdown-item">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="dropdown-icon">
          <path d="M12 12c2.97 0 5-2.198 5-5S14.97 2 12 2 7 4.198 7 7s2.03 5 5 5Zm-1 7.79c-3.75.522-6.5 2.337-6.5 4.46h15c0-2.123-2.75-3.938-6.5-4.46V16.5h2v-2H9v2h2v3.29Z" />
        </svg>
        <div className="dropdowncontent center-text">
          <p className="dropdown-links">
            <Link to="/amlservices">AML Screening services</Link>
          </p>
        </div>
      </li>
    </ul>
  );
};

export default Dropdown;