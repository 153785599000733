import React from 'react';
import './App.css';
import './index.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import Clients from './components/Clients';
import Footer from './components/footer';
import Ds from './components/digitalsignaturecertificate';
import Sign from './components/integratedsigningsolutions';
import Ssl from './components/sslcertificate';
import Blog1 from './components/blog1';
import Blog2 from './components/blog2';
import Blog3 from './components/blog3';
import Blog4 from './components/blog4';
import Blog5 from './components/blog5';
import Blog6 from './components/blog6';
import Blog7 from './components/blog7';
import Blog8 from './components/blog8';
import Blog from './components/blog';
import Signer from './components/documentsigner';
import SignCert from "./components/digitalsignaturecertificate";
import Integrated from './components/integratedsigningsolutions';
import Services from './components/services'
import Aboutus2 from './components/aboutus';
import Notintegrated from "./components/nonintegratedsigningsolutins"
import WebSigner from "./components/websignersolutin"
import DigitalSignVerifier from "./components/digitalsignverifier"
import SSLCert from "./components/sslcertificate"
import ScrollToTop from './components/scrollToTop';
import Consent from './components/consentmanager';
import Aml from './components/amlservices';
function App() {
  return (
    <div className='Signika'>
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={
          <>
          <div className='Agdasima'>
            <Home />
            </div>
            <Services />
            <Clients />
            {/* <Digitalsign/>
            <Signer/>
            <Nonintegrated/>
            <Integrated/>
            <Websigner/>
            <Verifier/>
            <Ssl/> */}

            <Blog />
            <Aboutus2 />
            <Footer />
            

          </>
        } />
        
        <Route path="/blog1" element={<> <Blog1 /> <Footer /> </>} />
        <Route path='/blog2' element={<> <Blog2 /> <Footer /> </>} />
        <Route path='/blog3' element={<> <Blog3 /> <Footer /> </>} />
        <Route path='/blog4' element={<> <Blog4 /> <Footer /> </>} />
        <Route path='/blog5' element={<> <Blog5 /> <Footer /> </>} />
        <Route path='/blog6' element={<> <Blog6 /> <Footer /> </>} />
        <Route path='/blog7' element={<> <Blog7 /> <Footer /> </>} />
        <Route path='/blog8' element={<> <Blog8 /> <Footer /> </>} />
        <Route path='/dss' element={<> <Ds /> <Footer /> </>} />
        <Route path='/ss' element={<> <Sign /> <Footer /> </>} />
        <Route path='/ssl' element={<> <Ssl /> <Footer /> </>} />
        <Route path='/integratedSoln' element={<> <Integrated /> <Footer /> </>} />
        <Route path='/nonintegratedSoln' element={<> <Notintegrated /> <Footer /> </>} />
        <Route path='/websignersoln' element={<> <WebSigner /> <Footer /> </>} />
        <Route path='/digitalSignVerify' element={<> <DigitalSignVerifier /> <Footer /> </>} />
        <Route path='/sslCert' element={<> <SSLCert /> <Footer /> </>} />
        <Route path='/consentmanager' element={<> <Consent /> <Footer /> </>} />
        <Route path='/amlservices' element={<> <Aml /> <Footer /> </>} />
        <Route path='/documentsigner' element={<> <Signer /> <Footer /> </>} />
        <Route path='/digitalsignCert' element={<> <SignCert /> <Footer /> </>} />
        
      </Routes>
    </Router>
    </div>
  );
}

export default App;
